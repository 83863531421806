import * as React from 'react';
import CircleWidgetButton from './CircleWidgetButton';
import CtaWidgetButton from './CtaWidgetButton';

export const WidgetButtonPicker = ({ buttonType, ...props }) => {
  if (props.hidden) {
    return null;
  }
  return buttonType === 'rounded' ? (
    <CircleWidgetButton {...props} />
  ) : (
    <CtaWidgetButton {...props} />
  );
};
