import React from 'react';
import CloseIcon from '../../../assets/icons/CloseIcon';

function CloseButton({ onClick, dockLeft, startColor, endColor }) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: dockLeft ? 'start' : 'end',
        justifyContent: dockLeft ? 'start' : 'end',
      }}
    >
      <div
        onClick={onClick}
        style={{
          width: '35px',
          height: '35px',
          borderRadius: 100,
          marginTop: '10px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: `linear-gradient(90deg, ${startColor} 11%, ${endColor} 100%)`,
          cursor: 'pointer',
        }}
      >
        <CloseIcon width={25} height={25} color="var(--ik-widget-text-color)" />
      </div>
    </div>
  );
}

export default CloseButton;
