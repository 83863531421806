import React from 'react';
import { hexToRgba } from '../../lib/color.js';

const CircleWidgetButton = ({
  hidden,
  themeStartColor,
  themeEndColor,
  dockLeft,
  formOpened,
  onClick,
  buttonRef,
}) => {
  const className = `widget-min-circle ${formOpened ? 'widget-min-hide' : ''} ${
    dockLeft ? 'widget-min-dock-left' : ''
  }`;

  const style = {
    display: hidden ? 'none' : 'flex',
    background: `linear-gradient(90deg, ${hexToRgba(
      themeStartColor
    )} 11%, ${hexToRgba(themeEndColor)} 100%)`,
    margin: window.screen.width < 768 ? '10px' : '15px',
    color: 'var(--ik-widget-text-color)',
  };

  return (
    <div style={style} ref={buttonRef} className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        className="bi bi-chat-left-text-fill"
        viewBox="0 0 16 16"
      >
        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm3.5 1a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
      </svg>
    </div>
  );
};

export default CircleWidgetButton;
