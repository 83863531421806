import React from 'react';
import { MessageIcon } from '../../assets/icons/MessageIcon';

function WidgetHeader({ startColor, endColor }) {
  const width = window.screen.width;

  return (
    <div
      className="widget__header"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(90deg, ${startColor} 11%, ${endColor} 100%)`,
        fontSize: width < 768 ? '12px' : '15px',
        color: 'var(--ik-widget-text-color)',
      }}
    >
      <div
        style={{
          marginTop: '6px',
          marginRight: '8px',
        }}
      >
        <MessageIcon />
      </div>
      Got questions? Text us for a quick reply!
    </div>
  );
}

export default WidgetHeader;
