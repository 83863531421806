import React from 'react';

const ChangeLocationBanner = ({ hidden, locationName, onChangeLocation }) => {
  if (hidden) return null;
  return (
    <div
      style={{
        fontSize: '12px',
        padding: '6px 15px',
        borderBottom: '1px solid lightgray',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
      }}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {locationName}
      </div>
      <button
        onClick={onChangeLocation}
        style={{
          border: 'none',
          padding: '0px 3px',
          backgroundColor: 'transparent',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          marginLeft: 'auto',
          color: 'gray',
          textDecoration: 'underline',
          fontSize: '12px',
        }}
      >
        Change location
      </button>
    </div>
  );
};

export default ChangeLocationBanner;
