import React from 'react';

const WelcomeMessageImage = ({ imageUrl, style }) => {
  return (
    <div style={{ width: '40px', height: '40px', ...style }}>
      <img
        src={imageUrl}
        alt="Ikeono Widget Greeter"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: 100,
          border: '2px solid #FFFFFF',
          objectFit: 'cover',
        }}
      />
    </div>
  );
};

export default WelcomeMessageImage;
