import React from 'react';
import WidgetHeader from '../common/WidgetHeader';
import CloseButton from '../common/Buttons/CloseButton';
import WidgetFooter from '../common/WidgetFooter';
import { hexToRgba } from '../../lib/color';

const WidgetContainer = ({
  dockLeft,
  themeStartColor,
  themeEndColor,
  widgetFormRef,
  toggleWidget,
  children,
  hideBranding,
}) => {
  const width = window.screen.width;

  return (
    <div
      ref={widgetFormRef}
      className="widget widget-hide"
      style={{
        left: dockLeft ? 0 : undefined,
        width: width < 768 ? '70%' : '360px',
        position: 'absolute',
        bottom: '0px',
      }}
    >
      <div
        style={{
          height: 'min(calc(-100px + 100vh), 700px)',
          borderRadius: '24px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.25)',
          background: 'var(--ik-widget-bg-color-10)',
        }}
      >
        <WidgetHeader
          startColor={hexToRgba(themeStartColor)}
          endColor={hexToRgba(themeEndColor)}
        />
        <div className="widget-container">
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'var(--ik-widget-bg-color-10)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {children}{' '}
          </div>
        </div>

        <WidgetFooter hidden={hideBranding} />
      </div>
      <CloseButton
        dockLeft={dockLeft}
        onClick={toggleWidget}
        startColor={hexToRgba(themeStartColor)}
        endColor={hexToRgba(themeEndColor)}
      />
    </div>
  );
};

export default WidgetContainer;
