import React, { useEffect } from 'react';

/*
  This can be used to simulate a config call.
*/

const LocalEventHandler = ({ onInit }) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' || window.parent) return;
    const data = {
      shopToken: 'ae6aa333-73a1-4bbb-b429-7cac75dc9236',
      formOpened: false,
      dockLeft: false,
      bottomOffset: 0,
      hideButton: false,
      isCTAWidget: true,
      welcomeMessage: 'Hi, let me know if you have a question.',
      themeStartColor: '#6F2DBD',
      themeEndColor: '#2E6AD9',
      textColor: '#FFFFFF',
      greeterImageUrl: 'https://static.ikeono.com/widget/lisa.png',
      googleAnalyticsEnabled: true,
      googleReviewsEnabled: true,
      googleRating: 4.8,
      locations: [
        {
          id: 'ae6aa333-73a1-4bbb-b429-7cac75dc9236',
          name: 'Ikeono Shop',
          addr_line_1: '123 Main St',
          addr_line_2: 'New York, NY 10026',
        },
        {
          id: 'e75db91f-6cec-44d9-b52d-7f756d95e536',
          name: 'Surf Shopzzz',
          addr_line_1: '123 Ocean Drive, Suite 1',
          addr_line_2: 'Miami, FL 32233',
        },
      ],
      source: 'LocalEventHandler',
    };
    onInit(data);
  }, []);

  return null;
};

export default LocalEventHandler;
