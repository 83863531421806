import React from 'react';
import MessageCard from '../MessageCard';
import GoogleLogo from './GoogleLogo';
import Stars from './Stars';

const GoogleReviewMessageCard = ({ hidden, rating }) => {
  if (hidden || !rating) return null;
  return (
    <MessageCard>
      <div style={{ display: 'flex', gap: '8px' }}>
        <GoogleLogo />
        <div>
          <span style={{ fontSize: '15px' }}>See why customers love us</span>
          <div
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyItems: 'center',
              fontSize: '20px',
            }}
          >
            <b>{rating}</b>
            <Stars rating={rating} />
          </div>
        </div>
      </div>
    </MessageCard>
  );
};

export default GoogleReviewMessageCard;
