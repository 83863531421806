export class LocalStorageKey {
    get = () => {
      try {
        return JSON.parse(window.localStorage.getItem(this.key));
      } catch (error) {
        console.log(error);
      }
    };
  
    exists = () => {
      return !!window.localStorage.getItem(this.key);
    };
  
    getAsDate = () => {
      return moment(window.localStorage.getItem(this.key));
    };
  
    getTimeDifferenceFromNow = () => {
      const now = moment();
      const keyAsDate = this.getAsDate();
      const duration = moment.duration(now.diff(keyAsDate));
      return duration.asSeconds();
    };
  
    isEnabled = () => {
      return this.get() === "true" || this.get() === true;
    };
  
    set = (obj) => {
      try {
        window.localStorage.setItem(this.key, JSON.stringify(obj));
      } catch (error) {
        console.log(error);
      }
    };
  
    setValueAsDateNow = () => {
      window.localStorage.setItem(this.key, new Date());
    };
  
    delete = () => {
      try {
        window.localStorage.setItem(this.key, null);
      } catch (error) {
        console.log(error);
      }
    };
  
    constructor(key) {
      this.key = key;
    }
  }