import React from 'react';
import WelcomeMessageImage from './WelcomeMessageImage';
import './style.css';
import WelcomeMessageCloseButton from './WelcomeMessageCloseButton';

function WelcomeMessagePopup({
  hidden,
  message,
  dockLeft,
  greeterImageUrl,
  bottomOffset,
}) {
  if (hidden) return null;
  const [_hidden, setHidden] = React.useState(
    hidden || sessionStorage.getItem('userClosedGreeterImage')
  );

  const onClick = () => {
    sessionStorage.setItem('userClosedGreeterImage', true);
    setHidden(true);
  };

  if (_hidden) return null;

  return (
    <div>
      <div
        className={
          dockLeft
            ? 'welcome-message-container widget-min-dock-left welcome-message-container-left'
            : 'welcome-message-container welcome-message-container-right'
        }
        style={{
          left: dockLeft ? 0 : undefined,
          right: dockLeft ? undefined : 0,
          bottom: bottomOffset,
        }}
      >
        <div className="welcome-message-overlay" />
        <WelcomeMessageImage imageUrl={greeterImageUrl} />
        <span style={{ marginRight: 'auto' }}>{message}</span>
        <WelcomeMessageCloseButton onClick={onClick} />
      </div>
    </div>
  );
}

export default WelcomeMessagePopup;
