import React from 'react';
import SearchIcon from '../../../assets/icons/SearchIcon';

const SearchInput = ({ onChange }) => {
  const [isFocused, setIsFocused] = React.useState(false);

  return (
    <div
      className={`search-container ${
        isFocused ? 'search-container-focused' : ''
      }`}
    >
      <div>
        <SearchIcon />
      </div>
      <input
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="search-input"
        style={{ border: 'none', margin: '0px', cursor: 'text' }}
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchInput;
