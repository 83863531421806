import React from 'react';

const Star = ({ isHighlighted = true }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="_5_Stars"
    data-name="5 Stars"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      id="Polygon_2"
      data-name="Polygon 2"
      d="M6.5,0,8.125,4.835,13,4.966,9.129,8.085,10.517,13,6.5,10.093,2.483,13,3.871,8.085,0,4.966l4.875-.131Z"
      fill={isHighlighted ? '#fcbf00' : 'rgb(227, 227, 227)'}
    />
  </svg>
);

const Stars = ({ rating }) => {
  const [parsedRating, setParsedRating] = React.useState();

  React.useEffect(() => {
    if (rating) {
      setParsedRating(Math.round(parseFloat(rating)));
    } else {
      setParsedRating(null);
    }
  }, [rating]);

  if (!parsedRating) return null;
  return (
    <div style={{ display: 'flex', gap: '3px' }}>
      <Star />
      <Star isHighlighted={parsedRating > 1} />
      <Star isHighlighted={parsedRating > 2} />
      <Star isHighlighted={parsedRating > 3} />
      <Star isHighlighted={parsedRating > 4} />
    </div>
  );
};

export default Stars;
