import React from 'react';

const GoogleLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_1631"
      data-name="Group 1631"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <rect
        id="Rectangle_795"
        data-name="Rectangle 795"
        width="40"
        height="40"
        rx="5"
        fill="#4285f4"
      />
      <path
        id="google"
        d="M21.245,8.553a11.713,11.713,0,0,1,.19,2.121,9.959,9.959,0,0,1-3.258,7.675h0a10.754,10.754,0,0,1-7.246,2.518A10.692,10.692,0,0,1,0,10.433,10.692,10.692,0,0,1,10.933,0a10.807,10.807,0,0,1,7.314,2.715L15.126,5.694a6.1,6.1,0,0,0-4.193-1.565A6.514,6.514,0,0,0,4.794,8.438a5.989,5.989,0,0,0,0,3.995h0a6.52,6.52,0,0,0,6.139,4.305,6.778,6.778,0,0,0,3.72-1h0a4.818,4.818,0,0,0,2.185-3.17H10.933V8.554Z"
        transform="translate(9.783 9.566)"
        fill="#fff"
      />
    </svg>
  );
};

export default GoogleLogo;
