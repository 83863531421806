import React, { useEffect } from 'react';

const ShimDataListener = ({ onInit, onOpenWidget, onCloseWidget }) => {
  const receiveMessage = (event) => {
    if (!!event && !event.data) return;

    if (event.data.type === 'INIT_IFRAME') {
      onInit(event.data);
    } else if (event.data.type === 'OPEN_WIDGET') {
      onOpenWidget(event.data);
    } else if (event.data.type === 'CLOSE_WIDGET') {
      onCloseWidget(event.data);
    }
  };

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
  }, []);

  return null;
};

export default ShimDataListener;
