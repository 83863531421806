import React from 'react';
import { LocalStorageKey } from '../../lib/localStorage';

const keys = {
  name: new LocalStorageKey('name'),
  phone: new LocalStorageKey('phone'),
};

const WidgetForm = ({ onFormChange, defaultMessage }) => {
  const [name, setName] = React.useState(keys.name.get());
  const [phone, setPhone] = React.useState(keys.phone.get());
  const [message, setMessage] = React.useState(defaultMessage);
  const [messageInputRef] = React.useState(React.createRef());
  const [nameInputRef] = React.useState(React.createRef());
  const width = window.screen.size;

  React.useEffect(() => {
    setMessage(defaultMessage);
  }, [defaultMessage]);

  React.useEffect(() => {
    const isValid = !!name && !!phone && !!message;
    onFormChange({ isValid, name, phone, message });
    keys.name.set(name);
    keys.phone.set(phone);
  }, [name, phone, message]);

  return (
    <form id="widgetForm">
      <label
        htmlFor="name"
        style={{
          fontFamily: 'Inter',
          fontWeight: '400',
          fontSize: width < 768 ? '12px' : '15px',
        }}
      >
        Name
      </label>
      <input
        ref={nameInputRef}
        id="name"
        aria-label="Your first and last name"
        required
        type="text"
        name="name"
        placeholder="First and Last Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        style={{
          fontFamily: 'inherit',
          fontSize: width < 768 ? '12px' : '15px',
        }}
      />
      <label
        htmlFor="phone"
        style={{
          fontFamily: 'Inter',
          fontWeight: '400',
          fontSize: width < 768 ? '12px' : '15px',
        }}
      >
        Phone Number
      </label>
      <input
        id="phone"
        aria-label="Mobile number"
        required
        type="text"
        name="phone"
        placeholder="(514) 555-5555"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        maxLength={30}
        minLength={9}
        style={{
          fontFamily: 'inherit',
          fontSize: window.screen.size < 768 ? '12px' : '15px',
        }}
      />
      <label
        htmlFor="message"
        style={{
          fontFamily: 'Inter',
          fontWeight: '400',
          fontSize: width < 768 ? '12px' : '15px',
        }}
      >
        Message
      </label>
      <textarea
        ref={messageInputRef}
        aria-label="How can we help you?"
        required
        rows={width < 768 ? 1 : 3}
        maxLength={1000}
        placeholder="How can we help you?"
        name="message"
        style={{
          fontFamily: 'inherit',
          fontSize: width < 768 ? '12px' : '15px',
        }}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
    </form>
  );
};

export default WidgetForm;
