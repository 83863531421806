import React from 'react';
import ikeonoLogo from '../../../img/connect-with-ik.svg';

function WidgetFooter({ hidden }) {
  const width = window.screen.width;
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottomLeftRadius: '24px',
        borderBottomRightRadius: '24px',
        minHeight: '15px',
      }}
    >
      <div
        style={{
          display: hidden ? 'none' : 'flex',
          gap: '3px',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: width < 768 ? '12px' : '13px',
          padding: '0px',
          margin: '4px',
        }}
      >
        <a
          href="https://www.ikeono.com/?utm_medium=product&utm_source=webchat&utm_term=prospecting"
          target="_blank"
          style={{
            textDecorationLine: 'underline',
            color: 'blue',
          }}
        >
          Start texting now
        </a>{' '}
        with
        <img
          src={ikeonoLogo}
          alt="Ikeono"
          style={{
            width: width < 768 ? '70px' : '100px',
            height: width < 768 ? '20px' : '25px',
            border: '2px solid #FFFFFF',
            objectFit: 'cover',
          }}
        />
      </div>
    </div>
  );
}

export default WidgetFooter;
