import React from 'react';
import LocationListItem from './LocationListItem';
import './style.css';
import SearchInput from './SearchInput';
import { stringIncludes } from '../../../lib/string';

const LocationList = ({ hidden, locations, onLocationSelect }) => {
  const [query, setQuery] = React.useState();
  const [filteredLocations, setFilteredLocations] = React.useState(locations);

  const onChange = (query) => {
    setQuery(query);

    const newLocations = locations?.filter(
      ({ name, addr_line_1, addr_line_2 }) => {
        return (
          stringIncludes(name, query) ||
          stringIncludes(addr_line_1, query) ||
          stringIncludes(addr_line_2, query)
        );
      }
    );
    setFilteredLocations(newLocations);
  };

  React.useEffect(() => {
    setFilteredLocations(locations);
  }, [locations]);

  if (hidden) return null;
  return (
    <div>
      <div
        style={{
          marginLeft: '12px',
          marginBottom: '13px',
          marginTop: '13px',
          fontSize: '15px',
        }}
      >
        Find the location closest to you
      </div>
      <div className="location-list">
        <SearchInput onChange={onChange} />
        {filteredLocations?.map(
          ({ id, name, addr_line_1, addr_line_2 }, idx) => {
            return (
              <LocationListItem
                key={idx}
                id={id}
                name={name}
                addrLine1={addr_line_1}
                addrLine2={addr_line_2}
                onClick={({ id }) => {
                  onLocationSelect({ id, name });
                }}
                hideBottomBorder={idx === filteredLocations?.length - 1}
              />
            );
          }
        )}
        <div
          hidden={filteredLocations?.length}
          style={{
            textAlign: 'center',
            marginTop: '24px',
            color: 'gray',
            marginBottom: '24px',
          }}
        >
          <i>No locations found.</i>
        </div>
      </div>
    </div>
  );
};

export default LocationList;
