import React from 'react';

const ResendMessageButton = ({ hidden, onClick }) => {
  if (hidden) return null;
  return (
    <div
      style={{
        width: '100%',
        marginBottom: '15px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <button
        onClick={onClick}
        style={{
          width: '80%',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          marginTop: 20,
          backgroundColor: 'black',
          cursor: 'pointer',
        }}
      >
        <p
          style={{
            color: 'white',
            fontSize: window.screen.width < 768 ? '12px' : '15px',
          }}
        >
          Send new message
        </p>
      </button>
    </div>
  );
};

export default ResendMessageButton;
