

export const postSendMessageEvent = (phoneNumber, customerName, origin, body, locationId) => {
    try {
        window.parent.postMessage(
          {
            event_name: 'send_message',
            event_source: 'ikeono',
            customer_mobile_number: phoneNumber,
            customer_name: customerName,
            origin: origin,
            body: body,
            location_id: locationId
          },
          '*'
        );
      } catch (e) {
        console.log(e);
      }
}