import React from 'react';
import LocationIcon from '../../../assets/icons/LocationIcon';

const LocationListItem = ({
  id,
  name,
  addrLine1,
  addrLine2,
  onClick,
  hideBottomBorder,
}) => {
  return (
    <div
      className={`list-item ${hideBottomBorder ? 'list-item-end' : ''}`}
      onClick={() => onClick({ id })}
    >
      <div style={{ paddingTop: '2px' }}>
        <LocationIcon color="var(--ik-widget-bg-color)" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span className="title">{name}</span>
        <div className="address">
          <span>{addrLine1}&#xfeff;</span>
          <span>{addrLine2}&#xfeff;</span>
        </div>
      </div>
    </div>
  );
};

export default LocationListItem;
