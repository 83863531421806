import React from 'react';
import LocationChat from './LocationChat';
import WidgetContainer from './WidgetContainer';
import LocationList from '../common/LocationList';
import ChangeLocationBanner from '../common/ChangeLocationBanner';

const WidgetNavigationManager = ({
  widgetFormRef,
  dockLeft,
  toggleWidget,
  themeStartColor,
  themeEndColor,
  defaultMessage,
  locations,
  googleAnalyticsEnabled,
  googleReviewsEnabled,
  googleRating,
  hideBranding,
}) => {
  const [currentLocation, setCurrentLocation] = React.useState();

  const onLocationSelect = (location) => {
    setCurrentLocation(location);
  };

  React.useEffect(() => {
    if (!locations || locations.length > 1) return undefined;
    setCurrentLocation(locations[0]);
  }, [locations]);

  if (!locations) return null;

  return (
    <WidgetContainer
      themeStartColor={themeStartColor}
      themeEndColor={themeEndColor}
      widgetFormRef={widgetFormRef}
      toggleWidget={toggleWidget}
      dockLeft={dockLeft}
      hideBranding={hideBranding}
    >
      <ChangeLocationBanner
        hidden={!currentLocation || locations?.length === 1}
        locationName={currentLocation?.name}
        onChangeLocation={() => {
          setCurrentLocation(null);
        }}
      />
      <LocationChat
        selectedShopToken={currentLocation?.id}
        widgetFormRef={widgetFormRef}
        dockLeft={dockLeft}
        toggleWidget={toggleWidget}
        themeStartColor={themeStartColor}
        themeEndColor={themeEndColor}
        defaultMessage={defaultMessage}
        hidden={!currentLocation?.id}
        googleRating={googleRating}
        googleReviewsEnabled={googleReviewsEnabled}
        googleAnalyticsEnabled={googleAnalyticsEnabled}
      />
      <LocationList
        locations={locations}
        hidden={!!currentLocation?.id}
        onLocationSelect={onLocationSelect}
      />
    </WidgetContainer>
  );
};

export default WidgetNavigationManager;
