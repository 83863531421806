import React from 'react';

const MessageCard = ({ children, hidden = false, direction = 'inbound' }) => {
  if (hidden) return null;
  const directionStyle = {
    borderBottomLeftRadius: 0,
    backgroundColor: '#F3F3F3',
  };

  if (direction === 'outbound') {
    directionStyle.backgroundColor = 'white';
    directionStyle.borderBottomLeftRadius = 30;
    directionStyle.borderBottomRightRadius = 0;
    directionStyle.marginLeft = 'auto';
  }

  return (
    <div
      style={{
        padding: '12px',
        width: '80%',
        borderRadius: 24,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        fontSize: '15px',
        fontWeight: 'light',
        ...directionStyle,
      }}
    >
      {children}
    </div>
  );
};

export default MessageCard;
